<template>
  <div>
    <div v-if="stillLoading">
      <vue-simple-spinner
        size="large"
        message="Loading..."
      ></vue-simple-spinner>
    </div>
    <div v-show="!stillLoading">
      <b-progress
        v-show="showProgress"
        :value="counterProgress"
        :max="100"
        show-progress
        animated
      ></b-progress>

      <b-col sm="12" tag="div">
        <b>
          <div slot="header">
            <strong style="color:black">
              {{ $route.meta.label }}

              <small> - Form </small>
            </strong>
          </div>

          <b-row tag="div">
            <b-col sm="12" tag="div">
              <vue-dropzone
                v-show="showProfileLogo"
                id="dropzone-logo"
                ref="dropzoneLogo"
                class="m-dropzone dropzone"
                :options="dropzoneLogoOptions"
                @vdropzone-success="dropzoneLogoSuccess"
                @vdropzone-removed-file="dropzoneRemovedSuccess"
              />
              <b-form-group
                breakpoint="sm"
                v-for="item in columns"
                v-bind:key="item.field"
              >
                <label for="name" style="color:black">
                  {{ item.label }}
                </label>
                <img :src="options[item.field]" v-if="item.field == 'url'" />

                <b-form-input
                  v-else-if="item.field == 'name'"
                  :placeholder="'Enter ' + item.label + ' ...'"
                  type="text"
                  readonly="true"
                  v-model="options[item.field]"
                  :disabled="
                    item.field == 'id' ||
                      item.field == 'created_date' ||
                      item.field == 'updated_date'
                  "
                />
                <b-form-input
                  v-else
                  :placeholder="'Enter ' + item.label + ' ...'"
                  type="text"
                  v-model="options[item.field]"
                  :disabled="
                    item.field == 'id' ||
                      item.field == 'created_date' ||
                      item.field == 'updated_date'
                  "
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            variant="primary"
            @click="submitData"
            v-if="($store.getters['customer/customer'].role_id = 1)"
          >
            <i class="fa fa-dot-circle-o" />
            Submit
          </b-button>
        </b>
      </b-col>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  name: 'KelasForm',
  mounted: function() {
    this.modeForm = this.$route.meta.mode;
    if (this.modeForm == 'Edit') {
      this.idForm = this.$route.params.id;
    }
    this.initData();
  },
  data: function() {
    return {
      options: {},
      showProgress: true,
      counterProgress: 0,
      idForm: 0,
      modeForm: null,
      columns: [
        { field: 'id', label: 'Id' },
        { field: 'waktu', label: 'Waktu' },
        { field: 'kode', label: 'Kode' },
        { field: 'nama', label: 'Nama' },
        { field: 'hp', label: 'HP' },
        { field: 'email', label: 'Email' },
        { field: 'ecommerce', label: 'Ecommerce' },
        { field: 'kelas', label: 'Kelas' },
        { field: 'flag', label: 'Flag' },
        { field: 'batch', label: 'Batch' },
        { field: 'provinsi', label: 'Provinsi' },
      ],
      masters: {},
      customer: {},
      levelId: 0,
      locked: false,
      stillLoading: true,
      dropzoneLogoOptions: {
        url: window.axios.defaults.baseURL + 'api/attachment',
        maxFilesize: 1,
        maxFiles: 1,
        addRemoveLinks: true,
        headers: {
          Authorization:
            'jwt ' + this.$store.getters['customer/customer'].token,
        },
      },
      showProfileLogo: false,
      profileLogo: '',
    };
  },
  methods: {
    dropzoneLogoSuccess: function(file, response) {
      //this.options.logo = response.data.path
      this.profileLogo = response.data.path;
      //this.$store.getters['customer/customer'].logo
    },
    initData() {
      if (
        this.$route.meta.name == 'customer' &&
        this.$route.meta.mode == 'Edit'
      ) {
        if (
          this.$route.params.id != this.$store.getters['customer/customer'].id
        ) {
          this.locked = true;
        }
      }
      if (this.locked) {
        return null;
      }

      this.counterProgress = 0;
      this.customer = this.$store.getters['customer/customer'];
      if (this.modeForm == 'Edit') {
        this.$store
          .dispatch('pijar/GET_PIJAR', {
            masterType: this.$route.meta.name,
            masterId: this.idForm,
          })
          .then((resp) => {
            if (resp.data.code == 200) {
              let tempData = this.$store.getters['pijar/pijar'];
              for (var key in tempData) {
                this.options[key] = tempData[key];
              }
            } else {
              // this.$swal(resp.data.message);
            }
            this.counterProgress = 100;
            this.showProgress = false;
            this.stillLoading = false;
          });
      } else {
        this.showProgress = false;
      }
    },
    submitData() {
      if (this.modeForm == 'Edit') {
        if (this.$route.meta.name == 'customer') {
          this.options.logo = this.profileLogo;
        }
        this.$store
          .dispatch('pijar/UPDATE_PIJAR', {
            masterType: this.$route.meta.name,
            masterId: this.idForm,
            payload: this.options,
          })
          .then((resp) => {
            if (resp.data.status == '200 OK') {
              if (this.$store.getters['customer/customer'].role_id == 1) {
                this.$router.push('/' + this.$route.meta.name);
              }
            }
            this.$swal(resp.data.status);
          });
      } else {
        this.$store
          .dispatch('pijar/CREATE_PIJAR', {
            masterType: this.$route.meta.name,
            masterId: this.idForm,
            payload: this.options,
          })
          .then((resp) => {
            if (resp.data.status == '200 OK') {
              if (this.customer.role_id > 2) {
                this.initData();
              } else {
                this.$router.push('/' + this.$route.meta.name);
              }
            }
            this.$swal(resp.data.status);
          });
      }
    },
    dropzoneRemovedSuccess: function(file, response) {
      this.profileLogo = '';
    },
  },
  computed: {
    data: function() {
      return this.$store.getters['pijar/list_pijar']
        ? this.$store.getters['pijar/list_pijar']
        : [];
    },
  },
  created: function() {
    //this.initData();
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  watch: {
    $route(to, from) {
      this.showProgress = true;
      this.initData();
    },
  },
};
</script>
